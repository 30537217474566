export default {
    "RISTRETTO 25мл": [
        {
            "name": "Ristretto Classico",
            "intensity": 9,
            "description": "фруктовые нотки, выраженный аромат обжарки",
            "imgUrl": "Ristretto Classico.png",
            "price": 1100
        },
        {
            "name": "Ristretto Intenso",
            "intensity": 12,
            "description": "перечные нотки + нотки специй и древесины",
            "imgUrl": "Ristretto Intenso.png",
            "price": 1100
        }
    ],
    "ESPRESSO 40мл": [
        {
            "name": "Voltesso",
            "intensity": 4,
            "description": "сладкие злаковые и бисквитные нотки",
            "imgUrl": "Voltesso.png",
            "price": 900
        },
        {
            "name": "Toccanto",
            "intensity": 5,
            "description": "нотки экзотических фруктов",
            "imgUrl": "Toccanto.png",
            "price": 1000
        },
        {
            "name": "Orafio",
            "intensity": 6,
            "description": "нотки карамели и пряностей",
            "imgUrl": "Orafio.png",
            "price": 1000
        },
        {
            "name": "Peru Organic",
            "intensity": 6,
            "description": "нотки сладких обжаренных злаков и экзотических фруктов",
            "imgUrl": "Peru Organic.png",
            "price": 1000
        },
        {
            "name": "Altissio",
            "intensity": 9,
            "description": "нотки злаков и горького какао",
            "imgUrl": "Altissio.png",
            "price": 1000
        },
        {
            "name": "Altissio Decaffeinato",
            "intensity": 9,
            "description": "нотки злаков и горького какао с пониженным содержанием кофеина",
            "imgUrl": "Altissio Decaffeinato.png",
            "price": 1000
        },
        {
            "name": "Diavolitto",
            "intensity": 11,
            "description": "нотки дуба и кожи",
            "imgUrl": "Diavolitto.png",
            "price": 1000
        },
        {
            "name": "Il Caffè",
            "intensity": 11,
            "description": "легкая горечь какао и злаковые нотки",
            "imgUrl": "Il Caffe.png",
            "price": 1000
        },
        {
            "name": "Bianco Piccolo",
            "intensity": 6,
            "description": "нотки карамели и бисквита",
            "imgUrl": "Bianco Piccolo.png",
            "price": 1150
        }
    ],
    "DOUBLE ESPRESSO 80мл": [
        {
            "name": "D.E. Dolce",
            "intensity": 5,
            "description": "нотки обжаренных злаков и солода",
            "imgUrl": "DE Dolce.png",
            "price": 1050
        },
        {
            "name": "D.E. Chiaro",
            "intensity": 8,
            "description": "древесные нотки",
            "imgUrl": "DE Chiaro.png",
            "price": 1050
        },
        {
            "name": "D.E. Scuro",
            "intensity": 11,
            "description": "нотки темного какао и ванили",
            "imgUrl": "DE Scuro.png",
            "price": 1050
        },
        {
            "name": "Bianco Doppio",
            "intensity": 6,
            "description": "бисквитные и злаковые нотки",
            "imgUrl": "Bianco Doppio.png",
            "price": 1250
        },
        {
            "name": "Ginseng Delight D.E.",
            "intensity": 6,
            "description": "нотки мягкой карамели с экстрактом женьшеня",
            "imgUrl": "Ginseng Delight DE.png",
            "price": 1250
        },
        {
            "name": "Festive Black D.E.",
            "intensity": 6,
            "description": "нотки древесины и специй",
            "imgUrl": "Festive Black.png",
            "price": 1500
        }
    ],
    "GRAN LUNNGO 150мл": [
        {
            "name": "Inizio",
            "intensity": 4,
            "description": "цветочные нотки + нотки обжаренных злаков",
            "imgUrl": "Inizio.png",
            "price": 1200
        },
        {
            "name": "Ethiopia",
            "intensity": 4,
            "description": "цветочные и мускатные нотки + нотки дикой голубики",
            "imgUrl": "Ethiopia.png",
            "price": 1250
        },
        {
            "name": "Arondio",
            "intensity": 6,
            "description": "нотки древесины какао и злаков",
            "imgUrl": "Arondio.png",
            "price": 1250
        },
        {
            "name": "Costa Rica",
            "intensity": 7,
            "description": "сладкие злаковые и солодовые нотки",
            "imgUrl": "Costa Rica.png",
            "price": 1250
        },
        {
            "name": "Fortado",
            "intensity": 8,
            "description": "нотки какао и древесины",
            "imgUrl": "Fortado.png",
            "price": 1200
        },
        {
            "name": "Fortado Decaffeinato",
            "intensity": 9,
            "description": "нотки какао и древесины с пониженным содержанием кофеина",
            "imgUrl": "Fortado Decaffeinato.png",
            "price": 1250
        }
    ],
    "LONG COFFEE 230мл": [
        {
            "name": "Solelio",
            "intensity": 2,
            "description": "нотки фруктов и обжаренных злаков",
            "imgUrl": "Solelio.png",
            "price": 1300
        },
        {
            "name": "Half Caffeinato",
            "intensity": 5,
            "description": "сладкие бисквитные нотки",
            "imgUrl": "Half Caffeinato.png",
            "price": 1300
        },
        {
            "name": "Colombia",
            "intensity": 5,
            "description": "фруктово-винные нотки с сочной кислинкой",
            "imgUrl": "Colombia.png",
            "price": 1350
        },
        {
            "name": "El Salvador",
            "intensity": 5,
            "description": "нотки печенья и клубничного джема",
            "imgUrl": "El Salvador.png",
            "price": 1350
        },
        {
            "name": "Melozio",
            "intensity": 6,
            "description": "медовые и злаковые нотки",
            "imgUrl": "Melozio.png",
            "price": 1300
        },
        {
            "name": "Melozio Decaffeinato",
            "intensity": 6,
            "description": "медовые и злаковые нотки с пониженным содержанием кофеина",
            "imgUrl": "Melozio Decaffeinato.png",
            "price": 1300
        },
        {
            "name": "Melozio Go",
            "intensity": 6,
            "description": "медовые и злаковые нотки с повышенным содержанием кофеина",
            "imgUrl": "Melozio Go.png",
            "price": 1350
        },
        {
            "name": "Mexico",
            "intensity": 7,
            "description": "нотки специй, выраженный аромат обжарки",
            "imgUrl": "Mexico.png",
            "price": 1350
        },
        {
            "name": "Odacio",
            "intensity": 7,
            "description": "с фруктовой кислинкой и злаковыми нотками",
            "imgUrl": "Odacio.png",
            "price": 1300
        },
        {
            "name": "Stormio",
            "intensity": 8,
            "description": "нотки пряностей и злаков",
            "imgUrl": "Stormio.png",
            "price": 1300
        },
        {
            "name": "Stormio Go",
            "intensity": 8,
            "description": "нотки пряностей и злаков с повышенным содержанием кофеина",
            "imgUrl": "Stormio Go.png",
            "price": 1350
        },
        {
            "name": "Intenso",
            "intensity": 9,
            "description": "нотки жженой карамели",
            "imgUrl": "Intenso.png",
            "price": 1300
        }
    ],
    "BARISTA CREATIONS 230мл": [
        {
            "name": "Bianco Forte",
            "intensity": 6,
            "description": "нотки обжаренных злаков",
            "imgUrl": "Bianco Forte.png",
            "price": 1350
        },
        {
            "name": "Sweet Vanilla",
            "intensity": 6,
            "description": "классический ванильный вкус, нотки сахарного бисквита и злаков",
            "imgUrl": "Sweet Vanilla.png",
            "price": 1350
        },
        {
            "name": "Golden Caramel",
            "intensity": 6,
            "description": "классический карамельный вкус, нотки сладкого бискита",
            "imgUrl": "Golden Caramel.png",
            "price": 1350
        },
        {
            "name": "Rich Chocolate",
            "intensity": 6,
            "description": "вкус темного шоколада, нотки карамели миндаля и злаков",
            "imgUrl": "Rich Chocolate.png",
            "price": 1350
        },
        {
            "name": "Roasted Hazelnut",
            "intensity": 6,
            "description": "вкус лесного ореха, карамельные и бисквитные нотки",
            "imgUrl": "Roasted Hazelnut.png",
            "price": 1350
        }
    ],
    "LIMITED EDITION 230мл": [
        {
            "name": "Seasonal Delight Spices",
            "intensity": 6,
            "description": "вкус новогодних специй и цитрусовых",
            "imgUrl": "Seasonal Delight Spices.png",
            "price": 1650
        },
        {
            "name": "Frosted Cramel Nuts",
            "intensity": 6,
            "description": "вкус карамелизированного миндаля и ванильного печенья",
            "imgUrl": "Frosted Cramel Nuts.png",
            "price": 1650
        },
        {
            "name": "Esperanza De Colombia",
            "intensity": 5,
            "description": "фруктовые и злаковые нотки",
            "imgUrl": "Esperanza de columbia.png",
            "price": 1950
        },
        {
            "name": "Infiniment Fruite",
            "intensity": 8,
            "description": "вкус малины",
            "imgUrl": "Infiniment Fruite.png",
            "price": 1450
        }
    ]
};
